<template>
    <div>
        <chartjs-component-line-chart :height="insights ? 333 : 210" :data="data" :options="options" :plugins="plugins" />
    </div>
</template>
<script>
import ChartjsComponentLineChart from '../../../components/LineChart.vue';
import themeConfig from '@themeConfig';
import moment from 'moment';

export default {
    components: {
        ChartjsComponentLineChart,
    },
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        insights: { type: Boolean, default: false },
    },

    setup(props) {
        const $themeColors = themeConfig.themes.dark;

        // const chartColors = {
        //     tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        //     barColor: '#d9d6ee',
        //     grid_line_color: 'rgba(200, 200, 200, 0.2)',
        // };

        const chartColors = {
            barColor: '#d9d6ee',
            grid_line_color: 'rgba(200, 200, 200, 0.2)',
            tooltipShadow: 'rgba(0, 0, 0, 0.25)',
            primaryColorShade: '#836AF9',
            yellowColor: '#ffe800',
            successColorShade: '#28dac6',
            warningColorShade: '#ffe802',
            warningLightColor: '#FDAC34',
            infoColorShade: '#299AFF',
            greyColor: '#4F5D70',
            blueColor: '#2c9aff',
            blueLightColor: '#84D0FF',
            greyLightColor: '#EDF1F4',
            tooltipShadow: 'rgba(0, 0, 0, 0.25)',
            lineChartPrimary: '#666ee8',
            lineChartDanger: '#ff4961',
            labelColor: '#6e6b7b',
            grid_line_color: 'rgba(200, 200, 200, 0.2)',
        }

        const formattedDates = props.chartData.dates.map(date => {
        	return moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
        });

        const chartFormat = {
            barThickness: 14,
            stepSize: formattedDates.length > 4 ? formattedDates.length / 4 : 1,
        };

        // Calculate bar thickness based on data length.
        const dataLength = props.chartData.bits.length;
        if (dataLength > 300) {
            chartFormat.barThickness = 3;
        } else if (dataLength > 200) {
            chartFormat.barThickness = 4;
        } else if (dataLength > 100) {
            chartFormat.barThickness = 5;
        } else if (dataLength > 50) {
            chartFormat.barThickness = 6;
        }

        const data = {
            labels: formattedDates,
            datasets: [
                {
                    data: props.chartData.bits,
                    label: 'Africa',
                    borderColor: chartColors.barColor,
                    lineTension: 0.4,
                    pointStyle: 'circle',
                    backgroundColor: chartColors.barColor,
                    fill: false,
                    pointRadius: 5,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 8,
                    pointBorderColor: $themeColors.pink,
                    pointHoverBackgroundColor: $themeColors.pink,
                    pointShadowOffsetX: 1,
                    pointShadowOffsetY: 1,
                    pointShadowBlur: 5,
                    // pointShadowColor: chartColors.tooltipShadow,
                },
            ]
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            backgroundColor: false,
            hover: {
                mode: 'label',
            },
            interaction: {
                intersect: true,
                axis: 'y',
				includeInvisible: true,
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                callbacks: {
					label: function (context) {
						return ' ' + Number(context.yLabel).toLocaleString() + ' Bits';
					},
					labelColor: function (context) {
						return {
							borderColor: $themeColors.white,
							backgroundColor: $themeColors.pink,
							borderWidth: 2,
							borderDash: [2, 2],
							borderRadius: 2,
						};
					},

					labelTextColor: function (context) {
						return $themeColors.white;
					},
					title: context => {
						return context[0].xLabel;
					},
				},
            },
            // layout: {
            //     padding: {
            //         top: -15,
            //         bottom: -25,
            //         left: -15,
            //     },
            // },
            scales: {
                xAxes: [
                    {
                        offset: true,
						grid: {
							color: 'blue',
						},
						type: 'time',
						time: {
							unit: 'day',
							stepSize: chartFormat.stepSize, // step size to skip the labels after each label
						},
						gridLines: {
							display: false,
						},
						ticks: {
							fontColor: $themeColors.white,
                            stepSize: chartFormat.stepSize,
							// maxTicksLimit: 6 // display number of labels in bottom axis
						},
                    },
                ],
                yAxes: [
                    {
                        beginAtZero: true,
						display: true,
						gridLines: {
							color: chartColors.grid_line_color,
							zeroLineColor: chartColors.grid_line_color,
						},
                        ticks: {
                            beginAtZero: true,
							fontColor: $themeColors.white,
							callback: function (label, index, labels) {
								return Number(label).toLocaleString();
							},
							precision: 0,
							maxTicksLimit: 5,
                        }
                    },
                ],
            },
            legend: {
                display: false,
                position: 'top',
                align: 'start',
                labels: {
                    usePointStyle: true,
                    padding: 25,
                    boxWidth: 9,
                },
            },
        }

        const plugins = [
            // to add spacing between legends and chart
            {
                beforeInit(chart) {
                    /* eslint-disable func-names, no-param-reassign */
                    chart.legend.afterFit = function () {
                        this.height += 20
                    }
                    /* eslint-enable */
                },
            },
        ]

        return { chartColors, data, options, plugins };
    },
};
</script>

<style lang="scss" scoped>
.apexcharts-bar-area:hover {
    fill: #ff0099 !important;
}
</style>
