<template>
	<div class="sales-breakdown">
		<v-card :min-height="insights ? 413 : 290">
			<v-card-text class="pt-3 pb-1">
				<div class="d-lg-flex justify-space-between align-center">
					<div>
						<h2 class="fs--23 fw--500">Bits Activity</h2>
						<h4 class="fs--13 fw--400">Total Bits: {{ Number(chartData.totalBits).toLocaleString() }}</h4>
					</div>
					<div class="d-flex flex-wrap">
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									color="secondary"
									depressed
									class="px-0 mr-2 mt-2 mt-sm-0"
									small
									dark
									v-bind="attrs"
									v-on="on">
									<template #default>
										<div class="d-flex align-center">
											<div class="d-flex align-center px-10 px-md-3 px-xl-10">
												<span>{{ graphType }} Graph</span>
											</div>
											<v-divider vertical class="mx-2"></v-divider>
											<v-icon class="mr-2">
												{{ icons.arrow }}
											</v-icon>
										</div>
									</template>
								</v-btn>
							</template>
							<v-list dense color="purple" class="bits-dropdown">
								<v-list-item @click="actions.changeGraph('bar')"> Bar </v-list-item>
								<v-list-item @click="actions.changeGraph('line')"> Line </v-list-item>
							</v-list>
						</v-menu>
						<!-- Date Range -->
						<DateRange @onDateRangeChange="actions.onDateChange" />
					</div>
				</div>

				<template v-if="graphType === 'bar'">
					<!-- Activity Chart -->
					<ActivityChart :insights="insights" :chartData="chartData" :key="chartKey" class="mt-3" />
				</template>

				<template v-if="graphType === 'line'">
					<!-- Activity Chart -->
					<ActivityLineChart :insights="insights" :chartData="chartData" :key="chartKey" class="mt-3" />
				</template>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import axios from '@axios';
import moment from 'moment';
import { reactive, ref, onMounted } from '@vue/composition-api';
import DateRange from '@/views/shared/DateRange.vue';
import ActivityLineChart from '@/views/streamer/home/ActivityLineChart.vue';
import ActivityChart from '@/views/streamer/home/ActivityChart.vue';
import { mdiChevronDown } from '@mdi/js';
export default {
	components: {
		DateRange,
		ActivityChart,
		ActivityLineChart,
	},
	props: {
		insights: { type: Boolean, default: false },
	},
	setup() {
		const graphType = ref('bar');
		const chartData = reactive({
			dates: [],
			bits: [],
			totalBits: 0,
		});

		const date = reactive({
			range: [moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
		});

		const chartKey = ref(1);

		onMounted(() => {
			const preference = JSON.parse(localStorage.getItem('preference'));

			if (preference && preference.graphType) {
				graphType.value = preference.graphType;
			}
		});

		const actions = {
			changeGraph: function (type) {
				graphType.value = type;

				const preference = JSON.parse(localStorage.getItem('preference'));

				if (preference) {
					const { leaderboardTab } = preference;
					localStorage.removeItem('preference');

					preference.leaderboardTab = leaderboardTab;
					preference.graphType = type;
					localStorage.setItem('preference', JSON.stringify(preference));
				} else {
					localStorage.setItem('preference', JSON.stringify({ leaderboardTab: 0, graphType: type }));
				}
			},
			onDateChange: function (item) {
				date.range = [];
				date.range.push(...item);

				actions.fetchData();
			},
			fetchData: function () {
				const start = moment(date.range[0]).format();
				const end = moment(date.range[date.range.length - 1]).format();

				axios.get(`/dashboard/sales-breakdown/${start}/${end}`).then(res => {
					Object.assign(chartData, res.data);
					chartKey.value = chartKey.value + 1;
				});
			},
		};

		actions.fetchData();

		return {
			graphType,
			actions,
			chartData,
			chartKey,
			date,
			icons: {
				arrow: mdiChevronDown,
			},
		};
	},
};
</script>
