<template>
	<v-data-table
		class="mt-1 activity-feed-table"
		:headers="headers"
		:items="rows"
		:items-per-page="5"
		:hide-default-footer="rows.length < 1"
		hide-default-header>
		<template v-slot:[`item.user`]="{ item }">
			<div class="d-flex align-center">
				<v-avatar size="30">
					<img
						:src="actions.profilePic(item.profilePic)"
						@error="$event.target.src = `${actions.defaultProfilePic()}`" />
				</v-avatar>
				<span class="fs--14 pl-2">{{ item.user }}</span>
			</div>
		</template>
		<template v-slot:[`item.value`]="{ item }">
			<div class="d-flex align-center">
				<img class="" width="20" :src="getBitIcon(item.value)" />
				<span class="fs--14 pl-2">{{ Number(item.value).toLocaleString() }}</span>
			</div>
		</template>
	</v-data-table>
</template>

<script>
import { getBitIcon } from '@/utils/utils';
export default {
	props: {
		headers: {
			type: Array,
			default: [],
		},
		rows: {
			type: Array,
			default: [],
		},
	},
	setup() {
		const actions = {
			defaultProfilePic: function () {
				return require('@/assets/images/misc/profile.png');
			},

			profilePic: function (profilePic) {
				if (profilePic) {
					return profilePic;
				} else {
					return actions.defaultProfilePic;
				}
			},
		};
		return {
			getBitIcon,
			actions,
		};
	},
};
</script>
