<template>
	<div>
		<chartjs-component-bar-chart :height="insights ? 333 : 210" :data="data" :options="options" />
	</div>
</template>
<script>
import ChartjsComponentBarChart from '../../../components/BarChart.vue';
import themeConfig from '@themeConfig';
import moment from 'moment';

export default {
	components: {
		ChartjsComponentBarChart,
	},
	props: {
		chartData: {
			type: Object,
			required: true,
		},
		insights: { type: Boolean, default: false },
	},

	setup(props) {
		const $themeColors = themeConfig.themes.dark;

		const chartColors = {
			tooltipShadow: 'rgba(0, 0, 0, 0.25)',
			barColor: '#d9d6ee',
			grid_line_color: 'rgba(200, 200, 200, 0.2)',
		};

		const formattedDates = props.chartData.dates.map(date => {
			return moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
		});

		const chartFormat = {
			barThickness: 14,
			stepSize: formattedDates.length > 4 ? formattedDates.length / 4 : 1,
		};

		// Calculate bar thickness based on data length.
		const dataLength = props.chartData.bits.length;
		if (dataLength > 300) {
			chartFormat.barThickness = 3;
		} else if (dataLength > 200) {
			chartFormat.barThickness = 4;
		} else if (dataLength > 100) {
			chartFormat.barThickness = 5;
		} else if (dataLength > 50) {
			chartFormat.barThickness = 6;
		}

		const data = {
			labels: formattedDates,
			datasets: [
				{
					data: props.chartData.bits,
					backgroundColor: chartColors.barColor,
					hoverBackgroundColor: $themeColors.pink,
					borderColor: 'transparent',
					barThickness: chartFormat.barThickness, // set bar thickness based on the number of records
					color: $themeColors.pink,
					clip: {
						left: 20,
						right: 20,
					},
				},
			],
		};

		const options = {
			elements: {
				rectangle: {
					borderWidth: 2,
					borderSkipped: '',
				},
			},
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
				axis: 'y',
				includeInvisible: true,
			},
			maintainAspectRatio: false,
			responsiveAnimationDuration: 500,
			legend: {
				display: false,
			},
			tooltips: {
				intersect: false,
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				shadowBlur: 8,
				shadowColor: chartColors.tooltipShadow,
				callbacks: {
					label: function (context) {
						return ' ' + Number(context.yLabel).toLocaleString() + ' Bits';
					},
					labelColor: function (context) {
						return {
							borderColor: $themeColors.white,
							backgroundColor: $themeColors.pink,
							borderWidth: 2,
							borderDash: [2, 2],
							borderRadius: 2,
						};
					},

					labelTextColor: function (context) {
						return $themeColors.white;
					},
					title: context => {
						return context[0].xLabel;
					},
				},
			},
			scales: {
				xAxes: [
					{
						offset: true,
						grid: {
							color: 'blue',
						},
						type: 'time',
						time: {
							unit: 'day',
							stepSize: chartFormat.stepSize, // step size to skip the labels after each label
						},
						gridLines: {
							display: true,
						},
						ticks: {
							fontColor: $themeColors.white,
							// maxTicksLimit: 6 // display number of labels in bottom axis
						},
					},
				],
				yAxes: [
					{
						beginAtZero: true,
						display: true,
						gridLines: {
							color: chartColors.grid_line_color,
							zeroLineColor: chartColors.grid_line_color,
						},
						ticks: {
							beginAtZero: true,
							fontColor: $themeColors.white,
							callback: function (label, index, labels) {
								return Number(label).toLocaleString();
							},
							precision: 0,
							maxTicksLimit: 5,
							// stepSize: 1 it will help to show only integers in y-axis you can set it dynamically for large values like x-axis
						},
					},
				],
			},
		};

		return { chartColors, data, options };
	},
};
</script>

<style lang="scss" scoped>
.apexcharts-bar-area:hover {
	fill: #ff0099 !important;
}
</style>
