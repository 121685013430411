<template>
	<div class="leaderboard">
		<v-card flat min-height="415">
			<v-card-text class="pb-0">
				<div class="d-sm-flex justify-space-between align-center">
					<h2 class="fs--23 fw--500">Leaderboard</h2>
				</div>
				<v-tabs v-model="tab" slider-color="pink" @change="setTab" color="white" grow>
					<v-tab><span class="fs--11 fw--400">All Time</span></v-tab>
					<v-tab><span class="fs--11 fw--400">Daily</span></v-tab>
					<v-tab><span class="fs--11 fw--400">Weekly</span></v-tab>
					<v-tab><span class="fs--11 fw--400">Monthly</span></v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab" class="mt-2 pb-4">
					<v-tab-item>
						<LeaderboardTable :headers="headers" :rows="data.all" />
					</v-tab-item>
					<v-tab-item>
						<LeaderboardTable :headers="headers" :rows="data.daily" />
					</v-tab-item>
					<v-tab-item>
						<LeaderboardTable :headers="headers" :rows="data.weekly" />
					</v-tab-item>
					<v-tab-item>
						<LeaderboardTable :headers="headers" :rows="data.monthly" />
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import axios from '@axios';
import { ref, reactive, onMounted } from '@vue/composition-api';
import LeaderboardTable from './LeaderboardTable.vue';

export default {
	components: {
		LeaderboardTable,
	},
	setup() {
		const tab = ref(0);
		const headers = [
			{
				text: 'Rank',
				value: 'rank',
			},
			{
				text: 'User',
				value: 'user',
			},
			{
				text: 'Value',
				value: 'value',
			},
		];

		const data = reactive({
			daily: [],
			weekly: [],
			monthly: [],
			all: [],
		});

		const actions = {
			fetchData: function () {
				axios.get('/dashboard/leaderboard').then(res => {
					Object.assign(data, res.data);
				});
			},
		};

		onMounted(() => {
			const preference = JSON.parse(localStorage.getItem('preference'));

			if (preference && preference.leaderboardTab) {
				tab.value = preference.leaderboardTab;
			}
		});

		const setTab = () => {
			const preference = JSON.parse(localStorage.getItem('preference'));

			if (preference) {
				const { graphType } = preference;
				localStorage.removeItem('preference');

				preference.leaderboardTab = tab.value;
				preference.graphType = graphType;
				localStorage.setItem('preference', JSON.stringify(preference));
			} else {
				localStorage.setItem('preference', JSON.stringify({ leaderboardTab: tab.value, graphType: 'bar' }));
			}
		};

		actions.fetchData();

		return {
			tab,
			setTab,
			headers,
			data,
		};
	},
};
</script>

<style lang="scss" scoped></style>
